exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adobe-commerce-experts-js": () => import("./../../../src/pages/adobe-commerce-experts.js" /* webpackChunkName: "component---src-pages-adobe-commerce-experts-js" */),
  "component---src-pages-b-2-b-web-design-js": () => import("./../../../src/pages/b2b-web-design.js" /* webpackChunkName: "component---src-pages-b-2-b-web-design-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-city-we-serve-js": () => import("./../../../src/pages/city-we-serve.js" /* webpackChunkName: "component---src-pages-city-we-serve-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../../../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-we-surve-js": () => import("./../../../src/pages/industry-we-surve.js" /* webpackChunkName: "component---src-pages-industry-we-surve-js" */),
  "component---src-pages-magento-commerce-cloud-express-package-js": () => import("./../../../src/pages/magento-commerce-cloud-express-package.js" /* webpackChunkName: "component---src-pages-magento-commerce-cloud-express-package-js" */),
  "component---src-pages-magento-commerce-js": () => import("./../../../src/pages/magento-commerce.js" /* webpackChunkName: "component---src-pages-magento-commerce-js" */),
  "component---src-pages-magento-support-js": () => import("./../../../src/pages/magento-support.js" /* webpackChunkName: "component---src-pages-magento-support-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-pay-per-click-js": () => import("./../../../src/pages/pay-per-click.js" /* webpackChunkName: "component---src-pages-pay-per-click-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-seo-audit-tool-js": () => import("./../../../src/pages/seo-audit-tool.js" /* webpackChunkName: "component---src-pages-seo-audit-tool-js" */),
  "component---src-pages-web-accessibility-guide-js": () => import("./../../../src/pages/web-accessibility-guide.js" /* webpackChunkName: "component---src-pages-web-accessibility-guide-js" */),
  "component---src-pages-web-design-barrie-js": () => import("./../../../src/pages/web-design-barrie.js" /* webpackChunkName: "component---src-pages-web-design-barrie-js" */),
  "component---src-pages-website-design-js": () => import("./../../../src/pages/website-design.js" /* webpackChunkName: "component---src-pages-website-design-js" */)
}

