import "./src/styles/global.css"

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
    
    // const currentPosition = getSavedScrollPosition(location) 
    setTimeout(function() {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        })  
    }, 100);
    // console.log('path');

    return false
  }
